/* pt-sans-caption-400normal - latin */
@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('PT Sans Caption Regular '),
    local('PT Sans Caption-Regular'),
    url('./files/pt-sans-caption-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/pt-sans-caption-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* pt-sans-caption-700normal - latin */
@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('PT Sans Caption Bold '),
    local('PT Sans Caption-Bold'),
    url('./files/pt-sans-caption-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/pt-sans-caption-latin-700.woff') format('woff'); /* Modern Browsers */
}

